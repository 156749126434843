<template>
    <div>
        <label class="form-label" v-if="label">{{ label }}:</label>
        <div class="input-group color-picker flex custom-picker" ref="colorpicker" style="width:100%">
            <input
                type="text" ref="input"
                v-bind="$attrs" class="color-input"
                :value="color"
                @focus="showPicker()" @input="updateFromInput"
                disabled
                placeholder="Pick colour..."
                v-if="!noInput"
            />

            <span class="input-group-addon color-picker-container" style="margin-left:auto;">
                <span class="current-color" :style="colorBox()" @click="togglePicker()"></span>
            </span>

            <div style="position:relative" class="custom-color">
                <!-- <chrome-picker :value="colors" @input="updateFromPicker"  :class="noInput ? 'vc-left' : 'vc-right'" /> -->
                <!-- <sketch-picker :value="colors" @input="updateFromPicker"  :class="noInput ? 'vc-left' : 'vc-right'" /> -->
                <compact-picker :palette="colorsList" :value="colors" @input="updateFromPicker"  :class="noInput ? 'vc-left' : 'vc-right'" />
                <!-- <swatches-picker :value="colors" @input="updateFromPicker"  :class="noInput ? 'vc-left' : 'vc-right'" /> -->
                <!-- <slider-picker :value="colors" @input="updateFromPicker"  :class="noInput ? 'vc-left' : 'vc-right'" /> -->
            </div>
        </div>

    </div>
</template>

<script>
import { Chrome,Sketch,Compact,Swatches,Slider } from 'vue-color'
export default {
    components: {
        'chrome-picker': Chrome,
        'sketch-picker': Sketch,
        'compact-picker': Compact,
        'swatches-picker': Swatches,
        'slider-picker': Slider,
    },
    inheritAttrs: false,
    props: {
        noInput:{
            type: Boolean,
            default: () => false
        },
        color: {
            type: String,
            default: () => '',
        },
        colorOptions: {
            type: Array,
            required:false,
            default: () => [],
        },
        label: {
            type: String,
            default: () => '',
        },
        chrome:{
            type: Boolean,
            default: () => true,
        },
        sketch:{
            type: Boolean,
            default: () => true,
        },
        compact:{
            type: Boolean,
            default: () => true,
        },
        swatches:{
            type: Boolean,
            default: () => true,
        },
        slider:{
            type: Boolean,
            default: () => true,
        },

    },
    data() {
        return {
            colors: {
                hex: '#000000',
            },
            colorValue: '',
            displayPicker: false,
            defaultColor: '#FF0000',
            colorsList:[ '#38B22D',
  "#FF6347", "#FF4500", "#FF8C00", "#FFA500", "#FFD700", "#FFA07A", "#FF7F50", "#FF69B4", "#FF1493", "#FF00FF",
  "#EE82EE", "#BA55D3", "#9370DB", "#8A2BE2", "#4B0082", "#6A5ACD", "#483D8B", "#4169E1", "#0000FF", "#000080",
  "#008B8B", "#008080", "#008000", "#6B8E23", "#808000", "#B8860B", "#D2691E", "#CD5C5C", "#8B0000", "#800000"
]
        }
    },

    methods: {
        colorBox(){
            let border = this.noInput ? '4px' : '0px 4px 4px 0px';
            return `background-color: ${this.colorValue};
                    border-radius: ${border}
                    `;
        },

        setColor(color) {
            this.updateColors(color);
            this.colorValue = color;
        },

        updateColors(color) {
            if(color.slice(0, 1) === '#') {
                this.colors = {
                    hex: color
                };
            }
            else if(color.slice(0, 4) === 'rgba') {
                let rgba = color.replace(/^rgba?\(|\s+|\)$/g,'').split(','),
                    hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1);
                this.colors = {
                    hex: hex,
                    a: rgba[3],
                }
            }
        },

        showPicker() {
            document.addEventListener('click', this.documentClick);
            this.displayPicker = true;
        },

        hidePicker() {
            document.removeEventListener('click', this.documentClick);
            this.displayPicker = false;
        },
        togglePicker() {
            this.displayPicker ? this.hidePicker() : this.showPicker();
        },

        updateFromInput() {
            this.updateColors(this.colorValue);
        },

        updateFromPicker(color) {
            this.colors = color;
            if(color.rgba.a === 1) {
                this.colorValue = color.hex;
            }
            else {
                this.colorValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
            }

            this.displayPicker = false;
        },

        documentClick(e) {
            let el = this.$refs.colorpicker,
                target = e.target;
            if(el !== target && !el.contains(target)) {
                this.hidePicker()
            }
        }
    },

    watch: {
        color(val){
            this.setColor(val);
        },
        colorValue(val) {
            if(val) {
                this.updateColors(val);
                this.$emit('input', val);
                //document.body.style.background = val;
            }
        }
    },

    mounted() {
        this.setColor(this.color || '#000000');
        if(this.colorOptions.length){
            this.colorsList = this.colorOptions;
        }
    }
}
</script>


<style>
    .vc-left{
        left: -39px;
    }
    .vc-right{
        right: 0;
    }
    .color-input{
        padding: .5rem;
        line-height: 1.5;
        display: block;
        width: 100%;
        border-width: 1px;
        color: #3d4852;
        background-color: #fff;
        border-radius: .25rem;
        text-align: left;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        position: relative;
    }
    .color-picker{
        position: relative;
        display: flex;
        width: 100%;
    }
    .vc-chrome,
    .vc-compact,
    .vc-sketch {
        position: absolute !important;
        /*bottom: 44px;*/
        top: 42px;
        z-index: 100;
    }
    .current-color {
        display: inline-block;
        width: 40px;
        height: 40px;
        background-color: #000;
        cursor: pointer;
    }
</style>